/* color definitions */
.greenColor {
  color: #65b32e;
}
.form-check-input:checked {
  background-color: #65b32e;
  border-color: #65b32e;
}
#registerform,
#registerformladepunkt {
  text-align: center;
  position: relative;
  margin-top: 20px;
}
#registerform fieldset,
#registerformladepunkt fieldset {
  background: #fff;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}
#registerform fieldset:not(:first-of-type),
#registerformladepunkt fieldset:not(:first-of-type) {
  display: none;
}
#registerform fieldset .form-card,
#registerformladepunkt fieldset .form-card {
  text-align: left;
}
#registerform fieldset .fs-title,
#registerformladepunkt fieldset .fs-title {
  font-size: 1.5rem;
  color: #65b32e;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: left;
}
#registerform fieldset .action-button,
#registerformladepunkt fieldset .action-button {
  width: 100px;
  background: #65b32e;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}
#registerform fieldset .form-control.warning,
#registerformladepunkt fieldset .form-control.warning {
  background-color: rgba(255, 0, 0, 0.2);
}
#registerform fieldset label.warning,
#registerformladepunkt fieldset label.warning {
  color: #ff0000;
}
#registerform .inputfile,
#registerformladepunkt .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
#registerform .kfzschein,
#registerformladepunkt .kfzschein {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}
#registerform .kfzschein img,
#registerformladepunkt .kfzschein img {
  width: 130px;
  margin: 0 auto 10px;
}
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  padding: 0;
}
#progressbar .active {
  color: #000000;
}
#progressbar #kontakt:before {
  font-family: "Font Awesome 5 Free";
  content: "\f007";
  z-index: 1;
  position: relative;
  font-weight: 900;
}
#progressbar #bank:before {
  font-family: "Font Awesome 5 Free";
  content: "\f09d";
  z-index: 1;
  position: relative;
  font-weight: 900;
}
#progressbar #kfz:before {
  font-family: "Font Awesome 5 Free";
  content: "\f1b9";
  z-index: 1;
  position: relative;
  font-weight: 900;
}
#progressbar #confirm:before {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  z-index: 1;
  position: relative;
  font-weight: 900;
}
#progressbar #tax:before {
  font-family: "Font Awesome 5 Free";
  content: "\f66f";
  z-index: 1;
  position: relative;
  font-weight: 900;
}
#progressbar #ladepunkt:before {
  font-family: "Font Awesome 5 Free";
  content: "\f1e6";
  z-index: 1;
  position: relative;
  font-weight: 900;
}
#progressbar #taxAddiotional {
  display: none;
}
#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 20%;
  float: left;
  position: relative;
}
#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
}
#progressbar li.active:before {
  background: #65b32e;
}
#progressbar li.active:after {
  background: #65b32e;
}
@media only screen and (max-width: 991px) {
  #progressbar li strong {
    display: none;
  }
}
